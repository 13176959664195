.bar {
    background-color: var(--clr-highlight);
}

.icon {
  background-color: var(--clr-highlight);
  border: none;
  text-align: center;
  text-decoration: none;
  margin: 10px;
  display: inline-block;
}

.icon img {
  width: 50px;
  height: auto;
  display: block;
  filter: invert(100%) sepia(66%) saturate(1147%) hue-rotate(281deg) brightness(102%) contrast(98%);
  color: var(--clr-text);
}

@media only screen and (max-width: 800px) {
    .icon img {
        width: 30px;
    }
}
