.results-title {
    text-align: center;
    margin-top: 15%;
}

.motivation {
    margin-top: 3vh;
}

.blurred {
    filter: blur(8px);
}
