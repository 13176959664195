.end-title {
    margin-top: 5%;
}

@media only screen and (max-width: 800px) {
    .end-title {
        margin-top: 15%;
    }
}

.time-to-next-game {
    margin-top: 5%;
}

.bold {
    font-weight: bold;
}
