.keyboard {
    user-select: none;
    background: var(--clr-highlight);
    height: 28vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    bottom: 0;
}

.keyboard-row {
    display: flex;
    width: 100%;
    height: 31%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.half-key {
    display: flex;
    background: var(--clr-highlight);
    width: 5%;
    height: 95%;
}

.key {
    border-radius: 10%;
    display: flex;
    color: var(--clr-text);
    background: var(--clr-background);
    width: 9%;
    height: 90%;
    margin: 1%;
    justify-content: center;
    align-items: center;
    font-size: var(--fs-large);
    font-family: var(--ff-body);
}

.big-key {
    border-radius: 10%;
    display: flex;
    color: var(--clr-text);
    background: var(--clr-background);
    width: 13.5%;
    height: 90%;
    margin-left: 1.5%;
    margin-right: 1.5%;
    margin-top: 1%;
    margin-bottom: 1%;
    justify-content: center;
    align-items: center;
    font-size: var(--fs-extra-large);
    font-family: var(--ff-body);
}


@media (pointer:fine) {
    .keyboard {
        display: none;
    }
}

