.monster-input {
    width: 100%;
    height: 25vh;
    font-weight: bold;
    border-width: 0px;
    border: none;
    caret-color: transparent;
    text-align: center;
    font-size: 15rem;
    color: var(--clr-text);
    text-transform:uppercase;
    font-family: var(--ff-body);
}

@media only screen and (max-width: 800px) {
    .monster-input {
    font-size: calc(28vw);
    }
}

.previous-word-holder {
    height: 10vh;
}

@media (pointer:fine) {
    .previous-word-holder {
        height: 40vh;
    }
}
.previous-word {
    margin-left: 2%;
    margin-right: 2%;
    color: var(--clr-text);
    font-size: var(--fs-medium);
    font-family: var(--ff-body);
}

.word-arrow {
    color: var(--clr-text);
    font-size: var(--fs-medium);
    font-family: var(--ff-body);
}

.hidden {
    visibility: hidden;
}

.faded {
    opacity: 50%;
}

.bottom-component {
    position: absolute;
    user-select: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
}

.contained {
    opacity: 0.7;
}

.uncontained {
    opacity: 1;
}

.score-container {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
}
